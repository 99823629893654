import { Button } from 'antd';
import styled from 'styled-components'
import { ColorWhite } from '../../Utils/Color';

function DownloadApp() {

  return (
    <Container>
      <Box>
        <Wrap>
          <Content>
            <Img />
            <ButtonWrap>
              <Button type="Default" style={{width: 200, padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10}}>앱에서 보기</Button>
              <Button type="primary" style={{width: 200, padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>설치하러 가기</Button>
            </ButtonWrap>
          </Content>
        </Wrap>
      </Box>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    // background-color: ${ColorWhite};
    `;
    
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 100px 80px;
    // background-color: ${ColorWhite};
    `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
  const Content = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
  `
  const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px 20px 100px 20px;
  `
  const Img = styled.img`
    width: 410px;
    height: 410px;
    background: lightgray;
  `

export default DownloadApp;