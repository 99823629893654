import { Input, Select, Space, Spin } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ColorBlack, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import { DualAxes, Line } from '@ant-design/plots';
import { DatePicker } from "antd";
import HeadersAdmin from '../../Components/HeadersAdmin';
import ReactDOM from 'react-dom';
import Footer from '../../Components/Footer';

function AdminMain() {
  const { Option } = Select;
  const { TextArea } = Input;
  const today = new Date();
  const year = today.getFullYear();
  const mm = today.getMonth()+1;
  const dd = today.getDate();
  const hh = today.getDate();
  const [isLoading, setIsLoading] = useState(false);
  const [search_date, setSearchDate] = useState();
  const [mdata, setData] = useState([]);
  const now = new Date;
  let month
  
  if(mm < 10){
    month = "0"+mm;
  }else{
    month = mm;
  }

  let day
  if(dd < 10){
    day = "0"+dd;
  }else{
    day = dd;
  }

  const save_date = year+'-'+month+'-'+day
  // const [date, setDate] = useState();

  const getData = async (e) => {
    console.log(search_date);
    console.log(e)
    console.log(now)
      try {
        const formdata = {
          search_date: e ? e : now,
        };
        const token = sessionStorage.getItem('token');
        const res = await API.get('/admin/new_dashboard', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
        setData(res.data);
        console.log('getData:', res.data)
        
      } catch (error) {
          if (axios.isAxiosError(error)) {
            console.log(error.response);
          }
      }
      setIsLoading(false);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const onSearchDate = (e) => {
    console.log(e)
    setSearchDate(e);
    getData(e);
  };

  const DemoLine = () => {
    const data = mdata.visit;
  
    const config = {
      data,
      xField: 'today',
      yField: 'total_count',
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
      interactions: [
        {
          type: 'marker-active',
        },
      ],
    };
    return <Line {...config} />;
  };

  const DemoLine2 = () => {
    const data = mdata.member;
    const config = {
      data,
      xField: 'today',
      yField: 'total_count',
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
      interactions: [
        {
          type: 'marker-active',
        },
      ],
    };
    return <Line {...config} />;
  };

  const DemoLine3 = () => {
    const data = mdata.sale;
    const config = {
      data,
      xField: 'today',
      yField: 'total_count',
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
      interactions: [
        {
          type: 'marker-active',
        },
      ],
    };
    return <Line {...config} />;
  };

  const DemoLine4 = () => {
    const data = mdata.complte;
    const config = {
      data,
      xField: 'today',
      yField: 'total_count',
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
      interactions: [
        {
          type: 'marker-active',
        },
      ],
    };
    return <Line {...config} />;
  };

  useEffect(() => {
    setIsLoading(true);
    const debounce = setTimeout(() => {
      return getData();
    }, 300);
    return () => clearTimeout(debounce);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
            <Wrap>
              <Title>대시보드</Title>
            </Wrap>
          </Wrap>
          <Wrap><span style={{paddingRight:"10px",fontWeight:'bold'}}>날짜조회</span>
            <DatePicker  
              id="search_date"
              format="YYYY-MM-DD"
              onChange={onSearchDate}
              // showNow={{defaultValue: dayjs('00:00:00'),}} 
              defaultValue={dayjs(today)}
              // format="YYYY-MM-DD"
              disabledDate={disabledDate}
              // disabledTime={disabledDateTime}
          />
          </Wrap>
          {isLoading ?
            <ChartWrap>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Spin tip="Loading...">
                  <Content>
                    <ChatTitle>-</ChatTitle>
                    { mdata.visit !== undefined ? <DemoLine style={{width: 'inherit'}}/> : null}
                  </Content>
                </Spin>
              </Space>
            </ChartWrap>
          :
            <>
              <ChartWrap>
                <Content>
                  <ChatTitle>방문자</ChatTitle>
                  { mdata.visit !== undefined ? <DemoLine style={{width: 'inherit'}}/> : null}
                </Content>
                <Content>
                  <ChatTitle>회원</ChatTitle>
                  { mdata.visit !== undefined ? <DemoLine2 /> : null}
                </Content>
              </ChartWrap>
              <ChartWrap>
                <Content>
                  <ChatTitle>판매중인 상품</ChatTitle>
                  { mdata.visit !== undefined ? <DemoLine3 /> : null}
                </Content>
                <Content>
                  <ChatTitle>판매완료된 상품</ChatTitle>
                  { mdata.visit !== undefined ? <DemoLine4 /> : null}
                </Content>
              </ChartWrap>
            </>
          }
          
        </Box>
      </Container>
      {/* <Footer /> */}
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 80px;
  background-color: ${ColorWhite};
  // width: 1400px;
  width: '100%';
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ChartWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  `;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  border: 1px solid #d9d9d9;
  padding: 30px;
  margin: 20px;
  width: -webkit-fill-available;
  align-items: stretch;
`
const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${ColorBlack};
  margin-right: 20px;
`;
const ChatTitle = styled.h3`
  font-weight: 600;
`


const InfoTitle = styled.p`
  margin: 5px 0;
  font-size: 15px;
  color: ${ColorBlack};
  font-weight: 500;
`;

const RowWrap = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 15px;
`;

const UnitText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  color: ${ColorBlack};
  font-weight: 500;
  line-height: 30px;
`;

const HideBox = styled.div`
  display: none;
`;

const inputStyle = { position: 'absolute', borderColor: 'transparent', fontSize: '13px', padding: 0, textAlign: 'center' };
const inputType1 = { width: '52px', height: '21px' };
const inputType2 = { width: '34px', height: '58px' };
const inputType3 = { width: '58px', height: '27px' };
const inputType4 = { width: '93px', height: '26px' };

export default AdminMain;