import { useNavigate, useParams } from "react-router-dom";
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Table, Input, Button, Select, Modal, Space } from 'antd';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import styled from 'styled-components';
import { API } from '../../Utils/API';
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useEffect, useState } from "react";
import { ReactSummernote } from '../../Components/Summernote';

function FaqListInfo() {
  const navigate = useNavigate();
  const idx = useParams();
  const id = idx.id;
  const [data, setData] = useState([]);
  const [title, setTitle] = useState(false);
  const [content, setContent] = useState(false);
  const [show, setShow] = useState(false);

  const getData = async() => {
    console.log(id)
    try {
      const res = await API.get(`admin/faq-detail?ft_idx=${id}`)
      setData(res.data.data[0])
      console.log(res)
      console.log(res.data.data[0])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const updateData = async() => {
    const bodyData = {
      "ft_idx": id,
      "ft_title": title,
      "ft_content": content,
      "ft_show": show
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/faq-edit`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      });
      Modal.success({ title: '자주하는 질문 수정 완료', content: '질문이 수정되었습니다.', 
      onOk: () => navigate(`/faqlist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const deleteData = async() => {
    try {
      const res = await API.get(`admin/faq-delete?ft_idx=${id}`)
      console.log(res.data.message)
      Modal.success({ title: '자주하는 질문 삭제 완료', content: '질문이 삭제되었습니다.', 
      onOk: () => navigate(`/faqlist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const onSaveBtn = (e) => {
    updateData()
  }

  const onDeleteBtn = () => {
    Modal.confirm({ title: '자주하는 질문 삭제', content: '삭제 하시겠습니까?', 
    onOk: () => deleteData(), okText: 'Ok' });
  }

  const onImageUpload = (img, insertImage) => {
    console.log(img)

    for (let i = 0; i < img.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        insertImage(reader.result);
      }
      reader.readAsDataURL(img[i]);
    
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>자주하는 질문 상세</Title>
          </Wrap>
          <Wrap style={{ marginBottom: 20, justifyContent: 'space-between' }}>
            <Wrap>
              <Timetext>등록일자</Timetext>
              <Time>{data.ft_wdate}</Time>
              <Timetext>(수정일자</Timetext>
              <Time>{data.ft_mdate === null ? '-' : data.ft_mdate})</Time>
            </Wrap>
            <Button type='Default' style={{ width: 100, height: 40 }} onClick={() => onDeleteBtn()} >삭제</Button>
          </Wrap>
          <Article>
            <Category>질문</Category>
            <Input style={{width: 1200}}
              value={ title === false ? data.ft_title && setTitle(data.ft_title) : title } 
              onChange={(e) => setTitle(e.target.value)}/>
          </Article>
          <Article>
            <Category>답변</Category>
            <Content style={{display: 'contents'}}>
            <ReactSummernote 
              options={{width: 1200, height: 350}} 
              value={ content === false ? data.ft_content && setContent(data.ft_content) : content } 
              onChange={(e) => setContent(e)}
              onImageUpload={onImageUpload}
            />
            </Content>
          </Article>
          <Article>
            <Category>노출 여부</Category>
            <Select
              type='Default'
              value={show === false ? data.ft_show && setShow(data.ft_show) : show } 
              onChange={(e) => setShow(e)}
              style={{ width: 150, textAlign: 'center'}}
              options={[
                { value: 'Y', label: '노출' },
                { value: 'N', label: '미노출' },
              ]}
              />
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/faqlist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => onSaveBtn()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.h4`
  width: 100px;
  min-width: 100px;
  word-break: keep-all;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
export default FaqListInfo;