import { Table, Input,Button,Select, Carousel, message, Popconfirm } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function TransactionList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);

    const initialDetailState = {
      ct_name :'',
      pt_content : '',
      pt_idx : null,
      pt_image1 : '',
      pt_image2 : '',
      pt_image3 : '',
      pt_image4 : '',
      pt_image5 : '',
      pt_image6 : '',
      pt_image7 : '',
      pt_image8 : '',
      pt_image9 : '',
      pt_image10 : '',
      pt_selling_price : null,
      pt_show : '',
      pt_title : '',
      pt_wdate : '',
    }

    const [detail,setDetail] = useState(()=>initialDetailState);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(10);
    const [pt_sale_now,  setPtsalenow] = useState('all');
    const [pt_show,  setPtshow] = useState('all');
    const [pt_declaration,  setPtdeclaration] = useState('all');
    const [itemIdx, setItemIdx] = useState(false);
    const [display, setDisplay] = useState('none');
    const [imgURLArr, setImgURLArr] = useState([]);
    const [dateTag, setDateTag] = useState(false);
    const [detailToggle, setDetailToggle] = useState(false);
    const ImgURL = 'https://getgoapi.dmonster.kr/uploads/';
    const [no, setNumber] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const [memberId, setMemberId] = useState(false);
    const [nickname, setNickname] = useState(false);
    const handleMouseEnter = (id, nickname) => { setIsHovering(id); setMemberId(id); setNickname(nickname); console.log('isHovering-'+id)}
    const handleMouseLeave = () => { setIsHovering(false);}

    const confirm = () => {
      message.info('해당 회원을 검색합니다.');
      setSearch(nickname)
      setMemberId(false)
    };

    // 회원 상세 이동
    const cancel = () => {
      navigate(`/members-info/${memberId}`)
    }

    const columns = [
      {
        
        title:"번호",
       
        render:((value, item, index) =>no-index )
          
   
       },
        {
       
        title: '거래상태',
        dataIndex: 'pt_sale_now',
        render: (data) => <span>{data === "1" ? '판매중' : (data === "2") ? '예약중' : (data === "3") ? '거래완료' : '판매중'  }</span>,
      },
      {
        
        title: '상품정보',
        dataIndex: 'pt_title',
       
        ellipsis: true,
        width:500,
       
        render:  (data,row) => 
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{display: 'flex'}}>
              <div style={{marginright: "10px", display: 'flex', flexDirection: 'column'  }}>
                <img src={`https://getgoapi.dmonster.kr/uploads/${row.pt_image1}`}  className='custom-bas'/>
              </div>
              <div style={{  flexDirection: 'column', marginLeft: "10px" }}>
                <div style={{paddingleft:"10px", flexDirection: 'row'}}>[{row.ct_name ? row.ct_name : '카테고리 없음'}]</div>
                <div style={{display: 'flex', alignItems: 'center'}}>{data.length > 30 ? `${data.substr(0,30)}...` : data}</div>
              </div>
            </div>
            <div>
              <Button 
                style={{display: 'flex', alignItems: 'center'}}
                onClick={(e) => {
                  getDetail(row.idx)
                }}
                // disabled={row.refundAmount}
                type="default"
                >상세</Button>
            </div>
          </div>
      },
      {
        
        title: '판매자',
        dataIndex: 'mt_seller_nickname',
        key: 'index',
        // render:  (data,row) => <span><div>{data}</div><div>{row.mt_seller_hp}</div></span>
        render: (data, row) => (
          <PopWrap>
            <Popconfirm
              placement="bottom"
              style={{display: 'flex', flexDirection: 'column'}}
              // title={text}
              // description={<button onClick={console.log('click')}>d</button>}
              onConfirm={confirm}
              onCancel={cancel}
              icon={null}
              cancelText="회원상세"
              okText="회원검색"
            >
              <span 
                onMouseEnter={() => handleMouseEnter(row.mt_seller_idx, row.mt_seller_nickname)} 
                onMouseLeave={() => handleMouseLeave()}
              >
                <NicknameBtn style={{borderBottom: (isHovering === row.mt_seller_idx) ? '1px solid black' : 'none'}}>
                  {/* {row.mt_seller_hp && data ? data : null} */}
                  {
                    row.mt_seller_hp !== null && data !== null 
                      ? data 
                      : row.mt_seller_hp !== null && !data
                      ? <NullData>(닉네임없음)</NullData>
                      : <NullData>(판매중)</NullData>
                  }
                </NicknameBtn>
                <div>{row.mt_seller_hp}</div>
              </span>
            </Popconfirm>
          </PopWrap>
        )
      },

      {
        
        title: '구매자',
        dataIndex: 'mt_nickname',
        
        // render:  (data,row) => <span><div>{data}</div><div>{row.mt_hp}</div></span>
        render: (data, row) => (
          row.mt_idx 
          ? <PopWrap>
              <Popconfirm
                placement="bottom"
                style={{display: 'flex', flexDirection: 'column'}}
                // title={text}
                // description={<button onClick={console.log('click')}>d</button>}
                onConfirm={confirm}
                onCancel={cancel}
                icon={null}
                cancelText="회원상세"
                okText="회원검색"
              >
                <span 
                  onMouseEnter={row.mt_idx ? () => handleMouseEnter(row.mt_idx, row.mt_nickname) : null} 
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <NicknameBtn style={{borderBottom: (isHovering === row.mt_idx) ? '1px solid black' : 'none'}}>
                    {
                      (row.mt_idx && data)
                        ? data
                        : <NullData>(닉네임없음)</NullData>
                    }
                  </NicknameBtn>
                  <div>{row.mt_hp}</div>
                </span>
              </Popconfirm>
            </PopWrap>
          : row.pt_sale_now !== '3' && <NullData>(판매중)</NullData>
          
        )
      },
      {
        
        title: '게시상태',
        dataIndex: 'pt_show',
        
        

      },
      {
        
        title: '신고',
        dataIndex: 'pt_declaration',
       
       

      },

      {
      
        title: '관리',
        dataIndex: 'idx',
       
        render: (data, row) =>
        data === 'initial' ? (
          <span>-</span>
        ) : (
          <Button 
            onClick={() => {
              navigate(`/transaction-info/${data}`)
            }}
            // disabled={row.refundAmount}
            type="primary"
          >
            상세
          </Button>
        ),
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
          pt_sale_now: pt_sale_now,
          pt_show: pt_show,
          pt_declaration: pt_declaration,
        };
        const token = sessionStorage.getItem('token');
        const res = await API.get('/admin/transactionlist', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
          setData(res.data.list);
          setTotal(res.data.total);
          const number = res.data.total-(page - 1) * amount;
          setNumber(number);
          console.log('받아온 데이터 목록: ', res.data.total)
          
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.log(error);
          }
        }
      };
      
    const getDetail = async (idx) => {
      try {
        const res = await API.get('admin/product-infolist?pt_idx='+idx);
        const resData = res.data
        console.log('getDetail: ',res)
        setDetail(resData.data[0])
        ImgCarousel(Object.values(resData.data[0]))
        detailDate()
        setDetailToggle(true)

      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    }

    const reset = async () => {
      setSearch('');
      setSelect('all');
      setPtsalenow('all');
      setPtshow('all');
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
          pt_sale_now: "all",
          pt_show: "all",
          pt_declaration: '',
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/transactionlist', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
          setData(res.data.list);
          setTotal(res.data.total);
          const number = res.data.total-(page - 1) * amount;
          setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    /* 이미지 배열 */
    let imgValArr = '';
    const ImgCarousel = (detailData) => {
      setImgURLArr([])
      imgValArr = detailData
      imgValArr.map((data, i) => {
        let imgName = `"${data}"`
        let imgNameLower = imgName.toLowerCase()
        if (imgNameLower.includes('png') || imgNameLower.includes('jpg') || imgNameLower.includes('jpeg') || imgNameLower.includes('bmp') || imgNameLower.includes('gif')) {
          imgURLArr.push(data)
          setImgURLArr((e) => [...e, data] )
        }
      })
    }

    const detailDate = () => {
      const wdate = detail.pt_wdate
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : '0' + (now.getMonth() + 1);
      const date = now.getDate() > 9 ? now.getDate() : '0' + now.getDate();
      const hour = now.getHours();
      const minute = now.getMinutes();

      const today = `${year}-${month}-${date}`

      const itemDate = wdate.slice(0,10);
      const itemHour = wdate.slice(11,13)*1;
      const itemMinute = wdate.slice(14,16)*1;

      let time = '';

      if ( today === itemDate){
        console.log('오늘 올라온 상품')
        if ( hour === itemHour ) {
          if ( minute === itemMinute ) {
            setDateTag(`방금 전`)
          } else {
            time = (minute = itemMinute)
            setDateTag(`${minute - itemMinute} 분 전`)
          }
        } else {
          time = (hour - itemHour)
          setDateTag(`${hour - itemHour} 시간 전`)
        }
      } else {
        setDateTag(itemDate)
      }
      // console.log(now)
      // console.log(`now: ${year}-${month}-${date}`)
      // console.log(date)
      // console.log('상품날짜: ',wdate.slice(0,10))
    }

    const onChangeImg = (currentSlide) => {
      console.log(currentSlide);
    };

    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };
    
    const onSelect = (value) => {
      setSelect(value);
    };
    
    const onClose = () => {
      // setDetail(initialDetailState)
      setImgURLArr([])
      setDetailToggle(false)
    }

    const onAll = () => {
      setPtshow('all')
      setPtdeclaration('all')
    }

    useEffect(()=>{
      setDetailToggle(false)
      console.log('detailDate',dateTag)
    },[detail])

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount, pt_sale_now, pt_show, pt_declaration, memberId]);

    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
        
      }
    }, []);

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
          <ItemContainer dp={detailToggle} style={{flex: 1/2}}>
            <Box style={{paddingRight: 0, paddingBottom: 20}}>
              <Wrap style={{justifyContent: 'space-between'}}>
                <Title style={{marginBottom: 10}}>상품정보</Title>
                <Button onClick={() => onClose()}>X</Button>
              </Wrap>
              <Carousel afterChange={onChangeImg}>
                {
                  imgURLArr.map((url,i) => {
                    // console.log(i, url)
                    return (
                      <div>
                        <Img src={`${ImgURL}${url}`} key={`{img-}${i}`}  className='custom-bas'/>
                      </div>
                    )
                  })
                }
              </Carousel>
            </Box>
            <ItemBox>
              <ItemWrap style={{justifyContent: 'space-between'}}>
                <Tag>{detail.ct_name ? detail.ct_name : '카테고리 없음'}</Tag>
                {
                  (dateTag.length < 1)
                    ? <Time>{detail.pt_wdate.slice(0,10)}</Time>
                    : <Time>{dateTag}</Time>
                }
              </ItemWrap>
              <ItemWrap style={{display: 'block'}}>
                <ItemTitle>{detail.pt_title}</ItemTitle>
                <text>{detail.pt_content}</text>
                <Line/>
                <Price>가격 {detailToggle ? (detail.pt_selling_price).toLocaleString() : null }</Price>
              </ItemWrap>
            </ItemBox>
          </ItemContainer>
        
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>거래관리</Title>
              </Wrap>
              </Wrap>
              <Wrap style={{ marginBottom: 10, justifyContent: 'space-between ' }}>
                <Wrap>
                  <span style={{ marginRight: 10  }}>
                    <Button type={pt_sale_now === 'all' ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtsalenow('all')}>
                    전체
                    </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={pt_sale_now === "1" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtsalenow('1')}>
                    판매중
                  </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={pt_sale_now === "2" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtsalenow('2')}>
                  예약중
                  </Button>
                  </span>
                  <span style={{ marginRight: 10  }}>
                  <Button type={pt_sale_now === "3" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtsalenow('3')}>
                  거래완료
                  </Button>
                  </span>
                </Wrap>
                <Wrap>
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 150, height:40 }}
                  options={[
                    { value: 'all', label: '통합검색' },
                    { value: 'seller_name', label: '판매자 닉네임' },
                    { value: 'seller_hp', label: '판매자 휴대폰번호' },
                    { value: 'mt_name', label: '구매자 닉네임' },
                    { value: 'mt_hp', label: '구매자 휴대폰번호' },
                    { value: 'pt_title', label: '상품명' },
                                     
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
                </Button>
                <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                  초기화
                </Button>
              </Wrap>
            </Wrap>
            <Wrap style={{ marginBottom: 30  }}>
              <span style={{ marginRight: 10  }}>
                <Button type={pt_show === 'all' && pt_declaration === "all" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => onAll()}>
                전체
                </Button>
              </span>
              <span style={{ marginRight: 10  }}>
              <Button type={pt_show === "Y" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtshow('Y')}>
                게시(Y)
              </Button>
              </span>
              <span style={{ marginRight: 10  }}>
              <Button type={pt_show === "N" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtshow('N')}>{/* setPtsalenow('N') */}
              게시안함(N)
              </Button>
              </span>
              <span style={{ marginRight: 10  }}>
              <Button type={pt_declaration === "Y" ? 'primary' : 'Default'}  style={{ width: 100, height: 40 }} onClick={() => setPtdeclaration('Y')}>
              신고(Y)
              </Button>
              </span>
             </Wrap>
             {console.log('filter:', filter, ' pt_sale_now:', pt_sale_now, ' pt_show:', pt_show, ' pt_declaration:', pt_declaration,'data:',data)}
            <Table
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 10, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
        
              
              scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }

  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const ItemContainer = styled.div`
    display: ${props => props.dp === true ? 'block' : 'none'};
    flex: 1/2;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `

  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;

  const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 0 0 80px;
    background-color: ${ColorWhite};
  `;

  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  const ItemWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  const Img = styled.img`
    width: 100%;
    height: 500px;
    background: #d9d9d9;
  `

  const Tag = styled.span`
    // width: 100px;
    height: auto;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    // border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
  `
  const Time = styled.span`
    color: ${ColorMainBlue};
    // margin: 10px;
  `
  const ItemTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
  `
  const Line = styled.div`
    width: auto;
    height: 1px;
    background: #d9d9d9;
    margin: 100px 0 20px 0;
  `
  const Price = styled.p`
    font-size: 20px;
    font-weight: 600;
  `

  const PopWrap = styled.div`
    marginLeft: 70,
    clear: 'both',
    whiteSpace: 'nowrap',
  `
  const NicknameBtn = styled.div`
    border: none,
    background: transparent,
    &:hover {
      // color: ${ColorMainBlue};
      border: 1px solid red;
    }
  `
  
  const NullData = styled.span`
    color: #d9d9d9;
  `

  export default TransactionList;