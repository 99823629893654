import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorBlack, ColorMainBlue } from '../Utils/Color';
import { Button, Input, message, Checkbox, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

function ReviewDetail({ title, infoData, setInfoData, type, index, size, width = '100%', height = '100%' }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeValues = (e) => {
   // console.log(e)
    const { value, name } = e.target;
    //console.log(type,index)
     setInfoData((prev) => ({ ...prev, [type]: { ...prev[type], [index]: { ...prev[type][index], [name]: value } } }));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadImage = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
    changeValues({ target: { name: 'image', value: info.file.originFileObj } });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const uploadButton = <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>Upload</Button>;

  useEffect(() => {
    const imgURL = infoData.image;
    console.log(imgURL);
    if (typeof imgURL !== 'object') {
      setImageUrl(imgURL);
    } else {
      setImageUrl(null);
    }
  }, [infoData.image]);

  return (
    <>

      <Menu className='borderbt'>
        <RowWrap>
          <MenuText style={{ alignSelf: 'flex-start' }}>등록일</MenuText>
          {infoData.rt_wdate}
        </RowWrap>
      </Menu>
   
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText style={{ alignSelf: 'flex-start' }}>만족도</MenuText>
          {infoData.rt_score === 1 ? '별로에요' : (infoData.rt_score === 2) ? '좋아요' : (infoData.rt_score === 3) ? '최고에요' : '좋아요'}
        </RowWrap>
      </Menu>
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText style={{ alignSelf: 'flex-start' }}>후기</MenuText>
          {infoData.rt_content}
        </RowWrap>
      </Menu>
 
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText style={{ alignSelf: 'flex-start' }}>상품정보</MenuText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{marginright: "10px", flexDirection: 'row'  }}>
                <Upload name="et_file1" listType="picture" showUploadList={false} beforeUpload={beforeUpload} onChange={uploadImage}>
                    {imageUrl ? <img src={imageUrl} alt="et_file1" style={{ width, height }}  crossOrigin='anonymous'/> : uploadButton}
                </Upload>
            </div>
            <div style={{  flexDirection: 'column',marginLeft: "10px" }}>
                <div style={{paddingleft:"10px",flexDirection: 'row'}}>
                    [{infoData.ct_name}]
                </div>
                <div>
                [{infoData.pt_title}]
                </div>
            </div>
         </div>
        </RowWrap>
      </Menu>
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText>구매자</MenuText>
          <div style={{  flexDirection: 'column',marginLeft: "10px" }}> 
            <div>{infoData.mt_hp}</div>
            <div>{infoData.mt_name}</div>
          </div>
        </RowWrap>
      </Menu>
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText>판매자</MenuText>
          <div style={{  flexDirection: 'column',marginLeft: "10px" }}> 
            <div>{infoData.mt_seller_hp}</div>
            <div>{infoData.mt_seller_nickname}</div>
         </div>
        </RowWrap>
      </Menu>
      <Menu className='borderbt'>
        <RowWrap>
          <MenuText>게시 여부</MenuText>
          <Checkbox checked={!!infoData.rt_show} onClick={() => changeValues({ target: { name: 'rt_show', value: "Y" } })}>
            노출
          </Checkbox>
          <Checkbox checked={!infoData.rt_show} onClick={() => changeValues({ target: { name: 'rt_show', value: "N" } })}>
            비노출
          </Checkbox>
        </RowWrap>
      </Menu>
    </>
  );
}

const Menu = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;
  &:hover {
    color: ${ColorMainBlue};
    cursor: pointer;
  }
  transition: 0.3s ease;
`;



const RowWrap = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const MenuText = styled.div`
  color: ${ColorBlack};
  font-size: 15px;
  font-weight: 500;
  min-width: 100px;
  max-width: 100px;
`;

export default ReviewDetail;
