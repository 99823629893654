import { useNavigate, useParams } from "react-router-dom";
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Table, Input, Button, Select, Modal, Space, Alert, Radio } from 'antd';
import { ColorMainBlue, ColorRed, ColorWhite } from '../../Utils/Color';
import styled from 'styled-components';
import { API } from '../../Utils/API';
import { useEffect, useState } from "react";
import axios from "axios";

function DeclarationListInfo() {
  const navigate = useNavigate();
  const idx = useParams();
  const id = idx.id
  const [value, setValue] = useState(false);
  const [display, setDisplay] = useState('none');
  const [data, setData] = useState([]);
  const [price, setPrice] = useState(false);
  const [type, setType] = useState();
  const [statusMemo, setStatusMemo] = useState(false);
  const [roomIdx, setRoomIdx] = useState(false);
  const [chat, setChat] = useState([]);
  const [nickname, setNickname] = useState();
  const [date, setDate] = useState(false);
  
  const ImgURL = 'https://getgoapi.dmonster.kr/uploads/';
  
  let chatSort = chat.sort((a, b) => (a.chat_idx - b.chat_idx))
  
  const getData = async() => {
    console.log('getData실행')
    console.log(id)
    try {
      const res = await API.get(`admin/declaration-detail?dt_idx=${id}`)
      console.log('res', res)
      setData(res.data.data[0])
      console.log('res2', res)
      setPrice(res.data.data[0].pt_selling_price.toLocaleString())
      console.log('res3', res)
      setType(res.data.data[0].dct_type)
      setRoomIdx(res.data.data[0].room_idx)
      console.log('getAPI - room id: ', res.data.data[0].room_idx )
      setNickname(res.data.data[0].mt_nickname)
      getChat()
      console.log('type',type)
    } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
    }
  }
  const getChat = async() => {
    console.log('roomIdx', roomIdx)
    try {
      const res = await API.get(`admin/chating-list?room_idx=${roomIdx}`)
      console.log(res)
      console.log(res.data)
      setChat(res.data)
    } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
    }
  }
  
  const updateData = async() => {
    const bodyData = {
      "dt_idx": id*1,
      "dct_type": type,
      "dct_status_memo": statusMemo,
      "dct_status": value
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/declaration-edit`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      });
      console.log(res.data.message)
      console.log('성공')
      Modal.success({ title: '신고내역 처리 완료', content: '변경한 내용으로 처리되었습니다.', 
      onOk: () => navigate(`/declarationlist`), okText: 'Ok' });
      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  
  useEffect(() => {
    const debounce = setTimeout(() => {
      return getData();
    }, 300);
    return () => clearTimeout(debounce);
  }, []);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Wrap style={{ display: (display === 'block') ? 'flex' : 'block'}}>
          <Box>
            <Wrap>
              <Title>신고 상세</Title>
            </Wrap>
            <HeaderWrap>
              <Timetext>승인상태처리일</Timetext>
              <Time>{data.dct_status_date === null ? '-' : data.dct_status_date}</Time>
            </HeaderWrap>
            <HeaderWrap>
              <Timetext>수정일</Timetext>
              <Time>{data.dct_mdate === null ? '-' : data.dct_mdate}</Time>
            </HeaderWrap>
            <Article style={{alignItems: 'flex-start'}}>
              <Category>승인상태</Category>
              <Content>
                <Wrap>
                  <Radio.Group onChange={onChange} value={value === false ? data.dct_status : value}>
                    <Radio value={1}>처리전</Radio>
                    <Radio value={2}>승인</Radio>
                    <Radio value={3}>반려</Radio>
                  </Radio.Group>
                  <Input placeholder='반려 사유 입력' style={{width: 500}}
                    value={statusMemo === false ? data.dct_status_memo : statusMemo}
                    onChange={(e) => setStatusMemo(e.target.value)}
                  />
                </Wrap>
                <Message style={{flexDirection: 'column'}}>
                  * 신고 즉시 신고한 게시글은 신고자에게 블라인드 처리됩니다.
                </Message>
              </Content>
            </Article>
            <Article>
              <Content>
                <Wrap>
                  <Category>신고구분</Category>
                  <text>{ (type < 3 || type === 5) ? '사용자신고' : '게시물신고' }</text>
                </Wrap>
                <Wrap>
                  <Category>신고게시글</Category>
                  <text>{data.rt_idx}<Message style={{color: 'darkgray'}}>-</Message></text>
                </Wrap>
              </Content>
            </Article>
            <Article>
              <Content>
                <Wrap style={{marginBottom: 10}}>
                  <Category>신고일자</Category>
                    <text>{data.dct_wdate}</text>
                </Wrap>
                <ItemWrap>
                  <Category>신고자</Category>
                  <Content>
                    <text>{data.mt_hp}</text>
                    <text>{data.mt_nickname}</text>
                  </Content>
                </ItemWrap>
                <Wrap style={{marginTop: 10}}>
                  <Category>신고사유</Category>
                  <text style={{maxWidth: '50%'}}>{data.dct_reason}</text>
                </Wrap>
              </Content>
            </Article>
            <Article style={{alignItems: (type === 1 || type === 5) ? 'center' : 'flex-start'}}>
              <Content>
                <ItemWrap>
                  <Category>작성자</Category>
                  <Content>
                    {
                      (type === 1 || type === 5)
                      ? null
                      : <>
                          <text>{data.wmt_hp ? data.wmt_hp : '-' }</text>
                          <text>{data.wmt_nickname ? data.wmt_nickname : <NullData>닉네임없음</NullData>}</text>
                        </>
                    }
                  </Content>
                </ItemWrap>
                  {/* 게시글 신고 일때 */}
                <DecalrationWrap display={(type === 1 || type === 5) ? 'none' : 'flex'}>
                  <Category>신고게시글</Category>
                  <Content>
                    <ItemWrap>
                      <Type>이미지</Type>
                      {
                        data.pt_image1 
                        ? <Img src={`${ImgURL}${data.pt_image1}`}  />
                        : <NullData>-</NullData>
                      }
                    </ItemWrap>
                    <ItemWrap>
                      <Type>상품군</Type>
                      {
                        data.ct_name
                        ? <text>[{data.ct_name}]</text>
                        : <NullData>-</NullData>
                      }
                    </ItemWrap>
                    <ItemWrap>
                      <Type>품목명</Type>
                      {
                        data.ct_name
                        ? <text>{data.pt_title}</text>
                        : <NullData>-</NullData>
                      }
                    </ItemWrap>
                    <ItemWrap>
                      <Type>상품내용</Type>
                      {
                        data.ct_name
                        ? <text>{data.pt_content}</text>
                        : <NullData>-</NullData>
                      }
                    </ItemWrap>
                    <ItemWrap>
                      <Type>가격</Type>
                      {
                        data.ct_name
                        ? <text>{price}</text>
                        : <NullData>-</NullData>
                      }
                    </ItemWrap>
                  </Content>
                </DecalrationWrap>
              </Content>
                {/* 채팅 신고 일때 */}
              <Content>
                <Button type="Default" style={{display: (type === 1 || type === 5) ? 'inline-block' : 'none'}}
                  onClick={() => setDisplay('block')}>채팅보기</Button>
              </Content>
            </Article>
          </Box>
          <Box style={{display: display}}>
            <Phone>
              <Header>
                채팅보기
                <CancelBtn type='Default' style={{width: 100}} onClick={() => setDisplay('none')}>X</CancelBtn>
              </Header>
              <Body style={{overflowY: 'overlay', height: 770, paddingTop: 60}}>
                <Wrap style={{justifyContent: 'center'}}>
                </Wrap>
                {
                  chatSort.map((data, i) => {
                    let newdate = data.ctt_sdate.substr(0,10);
                    let img = `${ImgURL}${data.mt_image1}`
                    return (
                      <>
                        <Wrap style={{justifyContent: 'center'}}>
                        </Wrap>
                        <ChatBox name={nickname === data.ctt_send_nickname ? 'flex-end' : 'flex-start'}>
                          <ChatWrap>
                            <Name>{data.ctt_send_nickname}</Name>
                            <Chat name={nickname === data.ctt_send_nickname ? 'flex-end' : 'flex-start'} color={data.declaration_yn === 'N' ? 'red' : '#E1ECF3'}>
                              <img style={{width: 200, height: 'auto'}}src={img} ></img>
                              <ChatText>{data.ctt_msg}</ChatText>
                            </Chat>
                            <ChatTime>{data.ctt_sdate}</ChatTime>
                          </ChatWrap>
                        </ChatBox>
                      </>
                    )
                  })
                }
              </Body>
            </Phone>
          </Box>
        </Wrap>
        {/* <Box>s</Box> */}
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/declarationlist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => updateData()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const HeaderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const DecalrationWrap = styled.div`
  display: ${ props => props.display};
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Message = styled.text`
  color: ${ColorRed};
  margin-top: 10px;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100px;
`
const Img = styled.img`
  width: 300px;
  height: 300px;
  // background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
const Phone = styled.div`
  width: 412px;
  height: 820px;
  background: #E1ECF3;
  box-shadow: 6px 7px 20px darkgrey;
`
const Header = styled.div`
  display: flex;
  position: absolute;
  width: 412px;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  background: white;
`
const Body = styled.div`
  display:flex;
  flex-direction: column;
  padding: 10px;
`
const Date = styled.div`
  top: 173px;
  left: 157px;
  width: 98px;
  height: 15px;
  color: #94A0A9;
  text-align: center;
`
const ChatBox = styled.div`
  display: flex;
  justify-content: ${ props => props.name};
  text-align: ${ props => props.name == 'flex-end' ? 'right' : 'left'};
  margin-bottom: 20px;
  margin-right: ${ props => props.name == 'flex-end' ? '20px' : 0};
`
const ChatBoxRight = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 20px;
`
const ChatBoxLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`
const ChatWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  `
  const Chat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  color: ${ props => props.name == 'flex-end' ? 'white' : '#02A256'};
  background: ${ props => props.name == 'flex-end' ? '#02A256' : '#FFFFFF'};
  border-radius: ${ props => props.name == 'flex-end' ? '30px 0px 30px 30px' : '0px 30px 30px 30px'};
  border: 4px solid ${props => props.color};
  padding: 12px 20px;
  opacity: 1;
`
const ChatRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  color: white;
  background: #02A256;
  border-radius: 30px 0px 30px 30px;
  border: 4px solid ${props => [props.color]};
  padding: 12px 20px;
  opacity: 1;
  `
  const ChatLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  color: #02A256;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 0px 30px 30px 30px;
  border: 4px solid ${props => [props.color]};
  padding: 12px 20px;
  opacity: 1;
`
const ChatText = styled.div`
  position: relative;
  text-align: left;
  word-break: break-all;
`
const ChatTime = styled.div`
  top: 256px;
  left: 336px;
  height: 14px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/23px var(--unnamed-font-family-pretendard);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---94a0a9-회색글자);
  font: normal normal normal 12px/23px Pretendard;
  letter-spacing: 0px;
  color: #94A0A9;
  opacity: 1;
`
const Name = styled.div`
  color: #94A0A9;
`
const CancelBtn = styled.button`
  width: 60px !important;
  margin-left: 100px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  background: none;
  border: none;
`

const NullData = styled.span`
  color: darkgray
`
export default DeclarationListInfo;