import styled from 'styled-components';
import { ColorMainBlue, ColorRed, ColorWhite } from '../../Utils/Color';
import { Table, Input, Button, Select, Modal, Space, Alert, Radio, TimePicker, DatePicker } from 'antd';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useNavigate } from 'react-router-dom';
import { API } from '../../Utils/API';
import { ReactSummernote } from '../../Components/Summernote';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios';

function PushSend() {
  const navigate = useNavigate();
  
  const { RangePicker } = DatePicker;
  const [url, setUrl] = useState(1);
  const [type, setType] = useState(1);
  const [target, setTarget] = useState(1);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState(false);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [urlContent, setUrlContent] = useState("");
  const [detail, setDetail] = useState();
  const [date, setDate] = useState("");
  const [time, setTime] = useState();
  const [targets, setTargets] = useState([])
  const [targetData, setTargetData] = useState([])
  let now = dayjs();

  const getData = async() => {
    try {
      const res = await API.get(`admin/member-search?keyword=${keyword}`);
      console.log(res)
      console.log(res.data.list)
      setData(res.data.list)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error)
      }
    }
  }

  const pushData = async() => {
    const bodyData = {
      "pst_type": `${type}`,
      // "push_type": 1, // 푸시타입 1:전체, 2:상품,3:후기,4:키워드,5:신고
      "pst_target": `${target}`,
      "pst_title": title,
      "pst_content": content,
      "pst_url_target": `${url}`,
      "pst_url": `${urlContent}`,
      "pst_detail_content": detail,
      "rev_date": `${date}`,
      "mt_idx" : targetData
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/push_send`, bodyData, {
        headers: {'Content-Type': 'application/json'},
      })
      console.log(res)
      Modal.success({ title: '메시지 발송 완료', content: '메시지가 발송되었습니다.', 
      onOk: () => navigate(`/pushlist`), okText: 'Ok' });
    } catch (error) {
      if(axios.isAxiosError(error)) {
        console.log(error)
      }
    }
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  
  const disabledDate = (current) => {
    return current < dayjs().startOf('day');
  };
  const disabledDateTime = () => ({
    // disabledHours: () => range(0, 24).splice(0, now.get('h')),
    // disabledMinutes: () => range(0, 60).splice(0, now.get('m')+5),
  });

  const onChangeType = (e) => {
    console.log('radio checked', e.target.value);
    setType(e.target.value);
  };

  const onChangeTarget = (e) => {
    console.log('radio checked', e.target.value);
    setTarget(e.target.value);
  };

  const onChangeUrl = (e) => {
    console.log('radio checked', e.target.value);
    setUrl(e.target.value);
  };

  dayjs.extend(customParseFormat);
  const onChangeDate = (e, value) => {
    console.log(e)
    console.log(value) // 날짜
    setDate(value)
  }

  const onOk = (value) => {
    console.log('onOk: ', value);
  };
  
  const selectTarget = (e) => {
    let name = e.target.value
    let idx = e.target.id
    console.log(idx)
    console.log(targets)
    console.log(targetData)
    if (targetData.includes(idx) === false) {
      setTargetData((prev => [...prev, idx]))
      setTargets((prev => [...prev, {idx, name}]))
    }
  }
  console.log('targets:' ,targets)

  const deleteTarget = (e) => {
    let value = e.target.value
    setTargetData(targetData.filter(prev => prev !== value))
    setTargets(targets.filter(prev => prev.idx !== value))
  }

  const Target = () => {
    const result = [];
    for (let i = 0; i < targets.length; i++) {
      result.push(
        <TargetTag>
          {targets[i].name}
          <CancelBtn value={targets[i].idx} onClick={(e) => deleteTarget(e)}>X</CancelBtn>
        </TargetTag>
      )
    }
    return result;
  };

  useEffect(() => {
    getData()
  }, [keyword])

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>메시지 발송</Title>
          </Wrap>
          <Article>
            <Category>발송타입<Pointer>*</Pointer></Category>
            <Radio.Group onChange={onChangeType} value={type}>
              <Space direction="" style={{alignItems: 'center'}}>
                <Radio value={1}>즉시</Radio>
                <Radio value={2}>예약</Radio>
                <TimeWrap display={type === 2 ? 'flex' : 'none'}>
                  <Space direction="vertical" size={12}>
                    <DatePicker 
                      showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss'),}} 
                      format="YYYY-MM-DD HH:mm:ss"
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                      onChange={onChangeDate} onOk={onOk} />
                  </Space>
                </TimeWrap>

              </Space>
            </Radio.Group>
          </Article>
          <Article>
            <Category>발송대상<Pointer>*</Pointer></Category>
            <LinkWrap>
              <Radio.Group onChange={onChangeTarget} value={target}>
                <Space direction="" style={{alignItems: 'center'}}>
                  <Radio value={1}>전체 발송</Radio>
                  <Radio value={2}>부분 발송</Radio>
                </Space>
              </Radio.Group>
              <TargetWrap display={target === 2 ? 'flex' : 'none'}>
                <SideCategory>부분 발송 회원 검색선택</SideCategory>
                <TargetBox>
                  <TargetList>
                    <Input style={{width: 400}}
                      onChange={(e) => setKeyword(e.target.value)} 
                      placeholder='휴대폰 번호, 닉네임으로 회원 검색'
                      />
                    {
                      data.map((value, i) => {
                        return(
                          <Input style={{width: 400}} readOnly 
                            value={keyword === false ? null : `${value.mt_nickname}`}
                            id={value.mt_idx}
                            onClick={keyword === false ? null : (e) => selectTarget(e)}
                          ></Input>
                          )
                        })
                      }
                  </TargetList>
                  <Targets>{Target()}</Targets>
                </TargetBox>
              </TargetWrap>
            </LinkWrap>
          </Article>
          <Article>
            <Category>메시지 제목<Pointer>*</Pointer></Category>
            <Input 
              style={{marginTop: 10, width: 1500}} 
              placeholder='내용을 입력해주세요.' 
              onChange={(e) => setTitle(e.target.value)}
            />
          </Article>
          <Article>
            <Category>메시지 내용<Pointer>*</Pointer></Category>
            <TextArea
              placeholder='내용을 입력해주세요.'
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={(e) => setContent(e.target.value)}
              style={{marginTop: 10, width: 1500}}
            />
          </Article>
          <Article>
            <Category>링크<Pointer>*</Pointer></Category>
              <LinkWrap>
                <Radio.Group onChange={onChangeUrl} value={url}>
                  <Space direction="" style={{alignItems: 'center'}}>
                    <Radio value={1}>없음</Radio>
                    <Radio value={2}>등록(앱 내 알림 상세 페이지로 이동)</Radio>
                    <Radio value={3}>등록(외부페이지로 이동)</Radio>
                  </Space>
                </Radio.Group>
                <Input style={{marginTop: 10, width: 1500}} 
                  placeholder='링크주소를 입력해주세요.'
                  onChange={(e) => setUrlContent(e.target.value)}
                />
              </LinkWrap>
          </Article>
          <Article>
            <Category>상세</Category>
            <ReactSummernote 
              options={{width: 1500, height: 350}} 
              onChange={(e) => setDetail(e)}
            />
          </Article>
        </Box>
        <Footer>
          <Button type='primary' style={{width: 200, height: 60}} onClick={() => pushData()}>발송하기</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  `
  const TimeWrap = styled.div`
  display: ${props => props.display};
  // flex-direction: column;
  // margin-top: 10px;
  // padding: 0 20px 20px;
  // background: #f5f5f5;
  // border: 1px solid #d9d9d9;
`
const TargetWrap = styled.div`
  display: ${props => props.display};
  flex-direction: column;
  margin-top: 10px;
  padding: 0 20px 20px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
`
const TargetBox = styled.div`
  display: flex;
  flex-direction: row;
`
const TargetList = styled.div`
  height: 100px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`
const Targets = styled.div`
  width: 1000px;
  height: 100px;
  overflow-y: scroll;
  // display: flex;
  flex-direction: column;
  background: white;
  margin-left: 20px;
  padding: 20px 10px;
  border: 1px solid #d9d9d9;
`
const TargetTag = styled.span`
  padding: 5px 10px 5px 13px;
  margin: 3px;
  background: #d9d9d9;
  border-radius: 5px;
  word-break: keep-all;
`
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 140px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const SideCategory = styled.div`
  font-weight: 600;
  margin: 10px 0 5px 0;
`
const LinkWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const Pointer = styled.span`
  color: ${ColorRed};
  margin-left: 3px;
  font-size: 20px;
`
const CancelBtn = styled.button`
  // margin-left: 3px;
  margin: 6px;
  background: none;
  border: none;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 80px;
  justify-content: center;
`

export default PushSend;