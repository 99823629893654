import { Table, Input,Button, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorRed, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation, useParams } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { ReactSummernote } from '../../Components/Summernote';

dayjs.locale('ko');

function SettingIn() {
  const navigate = useNavigate();
  const idx = useParams();
  const [data, setData] = useState([]);
  const [boss, setBoss] = useState(false);
  const [add, setAdd] = useState(false);
  const [email, setEmail] = useState(false);
  const [tel, setTel] = useState(false);
  const [companey1, setCompany1] = useState(false); // 사업자등록번호
  const [companey2, setCompany2] = useState(false); // 통신판매신고번호
  const [hosting, setHosting] = useState(false);
  const [agree1, setAgree1] = useState(false); // 이용약관 
  const [agree2, setAgree2] = useState(false); // 개인정보처리방침 
  const [agree3, setAgree3] = useState(false); // 위치기반서비스약관 
  
  const getData = async() => {
    try {
      const res = await API.get(`admin/setting_in`)
      console.log(res)
      setData(res.data.data[0])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }
  const updateData = async() => {
    const bodyData = {
      "st_idx": 1,
      "st_agree1": agree1,
      "st_agree2": agree2,
      "st_agree3": agree3,
      "st_company_boss": boss,
      "st_customer_tel": tel,
      "st_customer_email": email,
      "st_company_add": add,
      "st_company_num1": companey1,
      "st_hosting_name": hosting,
      "st_company_num2": companey2
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/setting-edit_in`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      })
      console.log(res.data)
      Modal.success({ title: '설정 수정 완료', content: '설정이 수정되었습니다.', 
      onOk: () => navigate(`/setting/in`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const onImageUpload = (img, insertImage) => {
    console.log(img)

    for (let i = 0; i < img.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        insertImage(reader.result);
      }
      reader.readAsDataURL(img[i]);
    
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10, borderBottom: 'none' }}>
            <Title>설정</Title>
          </Wrap>
          <Wrap>
            <Article>
              <Category>대표이사</Category>
              <Input value={boss === false ? data.st_company_boss && setBoss(data.st_company_boss) : boss}
                onChange={(e) => setBoss(e.target.value)}
              />
            </Article>
          </Wrap>
          <Wrap>
            <Article style={{ alignItems: 'flex-start'}}>
              <Category>주소</Category>
              <Input value={add === false ? data.st_company_add && setAdd(data.st_company_add) : add}
                onChange={(e) => setAdd(e.target.value)}
                style={{width: 530}}
              />
            </Article>
            <Article>
              <Category>전자우편주소</Category>
              <Input value={email === false ? data.st_customer_email && setEmail(data.st_customer_email) : email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Article>
            <Article>
              <Category>고객센터</Category>
              <Input value={tel === false ? data.st_customer_tel && setTel(data.st_customer_tel) : tel}
                onChange={(e) => setTel(e.target.value)}
              />
            </Article>
          </Wrap>
          <Wrap>
            <Article>
              <Category>사용자등록번호</Category>
              <Input value={companey1 === false ? data.st_company_num1 && setCompany1(data.st_company_num1) : companey1}
                onChange={(e) => setCompany1(e.target.value)}
              />
            </Article>
            <Article>
              <Category>통신판매신고</Category>
              <Input value={companey2 === false ? data.st_company_num2 && setCompany2(data.st_company_num2) : companey2}
                onChange={(e) => setCompany2(e.target.value)}
              />
            </Article>
            <Article>
              <Category>호스팅서비스제공자</Category>
              <Input value={hosting === false ? data.st_hosting_name && setHosting(data.st_hosting_name) : hosting}
                onChange={(e) => setHosting(e.target.value)}
              />
            </Article>
          </Wrap>
          <Content style={{alignItems: 'flex-start'}}>
            <Category>이용약관</Category>
            <Contentbox>
              <ReactSummernote 
                options={{width: 1200, height: 350}} 
                value={ agree1 === false ? data.st_agree1 && setAgree1(data.st_agree1) : agree1 }
                onChange={(e) => setAgree1(e)}
                onImageUpload={onImageUpload}
              />
            </Contentbox>
          </Content>
          <Content style={{alignItems: 'flex-start'}}>
            <Category>개인정보처리방침</Category>
            <Contentbox>
              <ReactSummernote 
                options={{width: 1200, height: 350}} 
                value={ agree2 === false ? data.st_agree2 && setAgree2(data.st_agree2) : agree2 } 
                onChange={(e) => setAgree2(e)}
                onImageUpload={onImageUpload}
              />
            </Contentbox>
          </Content>
          <Content style={{alignItems: 'flex-start'}}>
            <Category>위치정보서비스</Category>
            <Contentbox>
              <ReactSummernote 
                options={{width: 1200, height: 350}} 
                value={ agree3 === false ? data.st_agree3 && setAgree3(data.st_agree3) : agree3 } 
                onChange={(e) => setAgree3(e)}
                onImageUpload={onImageUpload}
              />
            </Contentbox>
          </Content>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() =>updateData()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
`;
const Article = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin-right: 30px;
`;
const Content = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  margin-right: 30px;
`;
const Contentbox = styled.div`
  margin: 10px 0;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  min-width: 200px;
  word-break: keep-all;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`


  export default SettingIn;