import styled from 'styled-components';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Table, Input, Button, Select, Modal, Space } from 'antd';
import axios from 'axios';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { ReactSummernote } from '../../Components/Summernote';

function FaqAddForm() {
  const navigate = useNavigate();
  const [title, setTitle] = useState(); // 질문값
  const [content, setContent] = useState(); // 답변값

  console.log(title)
  console.log(content)
  
  const onSave = async() => {
    const bodyData = {
      "ft_title": title,
      "ft_content": content
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/faq-save`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      });
      console.log(res.data.message)
      Modal.success({ title: '자주하는 질문 등록 완료', content: '질문이 저장되었습니다.', 
        onOk: () => navigate(`/faqlist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const onSaveBtn = () => {
    onSave()
  }

  const onImageUpload = (img, insertImage) => {
    console.log(img)

    for (let i = 0; i < img.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        insertImage(reader.result);
      }
      reader.readAsDataURL(img[i]);
    
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>자주하는 질문 신규 등록</Title>
          </Wrap>
          <Article>
            <Category>질문</Category>
            <Input style={{minWidth: 100, maxWidth: 1200}} onChange={(e) => setTitle(e.target.value)} />
          </Article>
          <Article>
            <Category>답변</Category>
            <ReactSummernote 
              placeholder='해당 문의에 대한 답변을 기재하세요.'
              options={{width: 1200, height: 350}} 
              onChange={(e) => setContent(e)}
              onImageUpload={onImageUpload}
            />
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/faqlist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => onSaveBtn()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
  word-break: keep-all;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
export default FaqAddForm;