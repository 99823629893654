import React, { useEffect, useState,useCallback } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Button, Divider, Modal, Alert, Space, Radio } from 'antd';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';

import ReviewDetail from '../../Components/ReviewDetail';
  

// 관리자 - 이벤트관리

function ReviewInfo() {
  const navigate = useNavigate();
  const { idx } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const getData = async() => {
    try {
      const res = await API.get(`/admin/review-info?idx=${idx}`)
      console.log(res)
      console.log(res.data.category[1])
      setData(res.data.category[1])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
}

 const updateData = async() => {
  const bodyData = {
    "rt_idx": idx,
    "rt_show": show
  }
  console.log(bodyData)
  try {
    const res = await API.post(`admin/review-edit`, bodyData, {
      headers: {'Content-Type' : 'application/json'},
    })
    console.log(res.data)
    Modal.success({ title: '후기 수정 완료', content: '후기가 수정되었습니다.', 
    onOk: () => navigate(`/reviewlist`), okText: 'Ok' });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response)
    }
  }
}

useEffect(() => {
  getData()
}, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>후기 상세</Title>
          </Wrap>
          <Wrap style={{ marginBottom: 20, justifyContent: 'space-between' }}>
            <Wrap>
              <Timetext>수정일</Timetext>
              <Time>{data.rt_mdate === null ? '-' : data.rt_mdate}</Time>
            </Wrap>
          </Wrap>
          <Article>
            <Category>등록일</Category>
            <text>{data.rt_wdate}</text>
          </Article>
          <Article>
            <Category>만족도</Category>
            <text>{data.rt_score === 1 ? '별로예요' : data.rt_score === 2 ? '좋아요' : '최고예요'}</text>
          </Article>
          <Article>
            <Category>후기</Category>
            <text>{data.rt_content}</text>
          </Article>
          <Article style={{alignItems: 'flex-start'}}>
            <Category>상품정보</Category>
            <Content>
              <ItemWrap>
                <Type>이미지</Type>
                <Img src={`${data.image}`}  />
              </ItemWrap>
              <ItemWrap>
                <Type>상품군</Type>
                <text>[{data.ct_name}]</text>
              </ItemWrap>
              <ItemWrap>
                <Type>품목명</Type>
                <text>{data.pt_title}</text>
              </ItemWrap>
            </Content>
          </Article>
          <Article>
            <Category>구매자</Category>
            <Content>
              <text>{data.mt_hp}</text>
              <text>{data.mt_name}</text>
            </Content>
          </Article>
          <Article>
            <Category>판매자</Category>
            <Content>
              <text>{data.mt_seller_hp}</text>
              <text>{data.mt_seller_nickname}</text>
            </Content>
          </Article>
          <Article>
            <Category>게시상태</Category>
            <Radio.Group onChange={(e) => setShow(e.target.value)} value={ show === false ? data.rt_show && setShow(data.rt_show) : show }>
              <Space direction="" style={{alignItems: 'center'}}>
                <Radio value='Y'>노출</Radio>
                <Radio value='N'>미노출</Radio>
              </Space>
            </Radio.Group>
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/reviewlist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => updateData()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const ImgWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100px;
`
const Img = styled.img`
  width: 200px;
  height: 200px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`

export default ReviewInfo;