import { useNavigate, useParams } from "react-router-dom";
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Button, Select, Modal } from 'antd';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import styled from 'styled-components';
import { API } from '../../Utils/API';
import { useEffect, useState } from "react";
import axios from "axios";

function TransactionInfo() {
  const navigate = useNavigate();
  const idx = useParams();
  const id = idx.id
  const [data, setData] = useState([])
  const [price, setPrice] = useState(false)
  const [declaration, setDeclaration] = useState(false)
  const [show, setShow] = useState(false)
  const [sellerId, setSellerId] = useState(false)
  const ImgURL = 'https://getgoapi.dmonster.kr/uploads/';
  
  const getData = async() => {
    try {
      const res = await API.get(`admin/transaction-detail?pt_idx=${id}`)
      console.log(res)
      setData(res.data.data[0])

      setPrice(res.data.data[0].pt_selling_price.toLocaleString())
      setSellerId(res.data.data[0].mt_seller_idx)
    } catch (error) {
      if (axios.isAxiosError(error)){
        console.log(error.response)
      }
    }
  }

  const updateTransaction = async() => {
    const bodyData = {
      "pt_idx": id,
      "pt_show": show,
      "pt_declaration": declaration,
      "mt_seller_idx" : `${data.mt_seller_idx}`
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/transaction-save`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      });
      console.log(res.data.message)
      console.log('성공')
      Modal.success({ title: '거래내역 수정 완료', content: '변경한 내용으로 수정되었습니다.', 
      /* onOk: () => navigate(`/transactionlist`), */ okText: 'Ok' });
      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error)
      }
    }
  }

  const deleteTransaction = async() => {
    try {
      const res = await API.get(`admin/transaction-delete?pt_idx=${id}&mt_seller_idx=${sellerId}`)
      console.log(res.data.message)
      Modal.success({ title: '거래내역 삭제 완료', content: '거래내역이 삭제되었습니다.', 
      onOk: () => navigate(`/transactionlist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  /* 이미지 배열 */
  let imgValArr = Object.values(data)
  console.log(imgValArr)
  let imgURLArr = [];
  const handleImg = () => {  
    imgValArr.map((data, i) => {
      let imgName = `"${data}"`
      let imgNameLower = imgName.toLowerCase()
      if (imgNameLower.includes('png') || imgNameLower.includes('jpg') || imgNameLower.includes('jpeg') || imgNameLower.includes('bmp') || imgNameLower.includes('gif')) {
        imgURLArr.push(data)
      }
    })
  }
  handleImg()


  const onSaveBtn = (e) => {
    if (show === false) {
      setShow(data.pt_show);
    }
    if (declaration === false) {
      setDeclaration(data.pt_declaration);
    }
    updateTransaction()
  }

  const onOutBtn = () => {
    Modal.confirm({ title: '거래내역 삭제', content: '정말로 삭제 하시겠습니까? 삭제한 상품내용은 복구 불가능합니다.', 
    onOk: () => deleteTransaction(), okText: 'Ok' });
  }

  useEffect(() => {
    getData();
  }, []);

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>거래관리 상세</Title>
          </Wrap>
          <Wrap style={{ marginBottom: 20, justifyContent: 'space-between' }}>
            <Wrap>
              <Timetext>수정일</Timetext>
              <Time>{data.pt_udate}</Time>
            </Wrap>
            <Button type='Default' style={{ width: 100, height: 40 }} onClick={() => onOutBtn()}>삭제</Button>
          </Wrap>
          <Article>
            <Category>신고</Category>
            <Select
              type='Default'
              value={declaration === false ? data.pt_declaration : declaration}
              style={{ width: 90, textAlign: 'center', marginRight: 100}}
              onChange={(e) => setDeclaration(e)}
              options={[
                { value: 'Y', label: 'Y' },
                { value: 'N', label: 'N' },
              ]}
              />
            <Category>게시상태</Category>
            <Select
              type='Default'
              value={show === false ? data.pt_show : show}
              style={{ width: 90, textAlign: 'center'}}
              onChange={(e) => setShow(e)}
              options={[
                { value: 'Y', label: 'Y' },
                { value: 'N', label: 'N' },
              ]}
              />
          </Article>
          <Article>
            <Category>거래상태</Category>
            <text>{data.pt_sale_now === 1 ? '판매중' : (data.pt_sale_now === 2) ? '예약중' : '거래완료'}</text>
          </Article>
          <Article style={{alignItems: 'flex-start'}}>
            <Category>상품정보</Category>
              <Content>
                <ItemWrap>
                  <Type>이미지</Type>
                  <ImgWrap style={{display: 'flex'}}>
                  {
                    imgURLArr.map((url,i) => {
                      return (
                        <Img style={{marginBottom: 15}} src={`${ImgURL}${url}`} key={i}  className='custom-bas'/>
                      )
                    })
                  }
                  </ImgWrap>
                </ItemWrap>
                <ItemWrap>
                  <Type>상품군</Type>
                  <text>[{data.ct_name}]</text>
                </ItemWrap>
                <ItemWrap>
                  <Type>품목명</Type>
                  <text>{data.pt_title}</text>
                </ItemWrap>
                <ItemWrap>
                  <Type>상품내용</Type>
                  <text>{data.pt_content}</text>
                </ItemWrap>
                <ItemWrap>
                  <Type>가격</Type>
                  <text>{price}</text>
                </ItemWrap>
              </Content>
          </Article>
          <Article>
            <Category>구매자</Category>
            <Content>
              <text>{data.mt_hp}</text>
              <text>{data.mt_nickname}</text>
            </Content>
          </Article>
          <Article>
            <Category>판매자</Category>
            <Content>
              <text>{data.mt_seller_hp}</text>
              <text>{data.mt_seller_nickname}</text>
            </Content>
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/transactionlist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => onSaveBtn()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const ImgWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100px;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
export default TransactionInfo;