import styled from 'styled-components';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Table, Input, Button, Select, Modal, Space, Alert, message, Upload } from 'antd';
import axios from 'axios';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import { useNavigate } from 'react-router-dom';
import { ReactSummernote } from '../../Components/Summernote';
import { useEffect, useState } from 'react';

import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

function NoticeAddForm({infoData, index, type, setInfoData}) {
  const navigate = useNavigate();
  const [content, setContent] = useState();
  const [title, setTitle] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const saveData = async() => {
    const bodyData = {
      "nt_title": title,
      "nt_content": content
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/notice-save`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      })
      console.log(res.data)
      Modal.success({ title: '공지사항 등록 완료', content: '공지사항이 등록 되었습니다.', 
      onOk: () => navigate(`/noticelist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  const uploadImg = async(img) => {
    const form = new FormData();
    form.append('img', img);

    let entries = form.entries();
    for (const pair of entries) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
    // const image = { "image": img}
    // console.log(img)
    
    try {
      const res = await API.post(`admin/upload`, form, {
        headers: {'Content-Type' : 'application/json'},
      })
      console.log(res)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error)
      }
    }
  }

  const onImg = (e) => {
    let img = e[0].name
    console.log(e)
    // setImg(e[0].name)
    uploadImg(img)
  }

// 1.
  const onImageUpload = (img, insertImage) => {
    console.log(img)

    for (let i = 0; i < img.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        insertImage(reader.result);
        // insertImage(e => console.log(e));
      }
      reader.readAsDataURL(img[i]);
    
    }
  }



  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>공지사항 신규 등록</Title>
          </Wrap>
          <Article>
            <Category>질문</Category>
            <Input style={{width: 1500}} 
              onChange={(e) => setTitle(e.target.value)}
            />
          </Article>
          <Article>
            <Category>답변</Category>
            <ReactSummernote 
              options={{width: 1500, height: 350}} 
              onChange={(e) => setContent(e)}
              // beforeUpload={beforeUpload}
              // onImageUpload={(e) => onImg(e)} // 0.
              onImageUpload={onImageUpload} // 1.
            />
          </Article>
        </Box>
        <Footer>

          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/noticelist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => saveData()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
  word-break: keep-all;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
export default NoticeAddForm;