import { useNavigate, useParams } from "react-router-dom";
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Input, Button, Select, Modal } from 'antd';
import { ColorMainBlue, ColorRed, ColorWhite } from '../../Utils/Color';
import styled from 'styled-components';
import { API } from '../../Utils/API';
import { ReactSummernote } from "../../Components/Summernote";
import { useEffect, useState } from "react";
import axios from "axios";

function NoticeListInfo() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(false);
  const [content, setContent] = useState(false);
  const idx = useParams();
  const id = idx.id

  const getData = async() => {
    try {
      const res = await API.get(`admin/notice-detail?nt_idx=${id}`)
      let resData = res.data
      console.log(res)
      setData(...resData.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }
  
  const updateData = async() => {
    const bodyData = {
      "nt_idx": id,
      "nt_title": title,
      "nt_content": content,
      "nt_show": show
    }
    console.log(bodyData)
    try {
      const res = await API.post(`admin/notice-edit`, bodyData, {
        headers: {'Content-Type' : 'application/json'},
      })
      console.log(res.data)
      Modal.success({ title: '공지사항 수정 완료', content: '공지사항이 수정되었습니다.', 
      onOk: () => navigate(`/noticelist`), okText: 'Ok' });
    } catch (error) {
      
    }
  }

  const deleteData = async() => {
    try {
      const res = await API.get(`admin/notice-delete?nt_idx=${id}`)
      console.log(res.data.message)
      Modal.success({ title: '공지사항 삭제 완료', content: '공지사항이 삭제되었습니다.', 
      onOk: () => navigate(`/noticelist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  } 

  const onDeleteBtn = () => {
    Modal.confirm({ title: '공지사항 삭제', content: '삭제 하시겠습니까?', 
    onOk: () => deleteData(), okText: 'Ok' });
  }

  const onImageUpload = (img, insertImage) => {
    console.log(img)

    for (let i = 0; i < img.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        insertImage(reader.result);
        // insertImage(e => console.log(e));
      }
      reader.readAsDataURL(img[i]);
    
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>공지사항상세</Title>
          </Wrap>
          <Wrap style={{ marginBottom: 20, justifyContent: 'space-between' }}>
            <Wrap>
              <Timetext>수정일</Timetext>
              <Time>{data.nt_udate === null ? '-' : data.nt_udate}</Time>
            </Wrap>
            <Button type='Default' style={{ width: 100, height: 40 }} onClick={() => onDeleteBtn()}>삭제</Button>
          </Wrap>
          <Article>
            <Category>등록일</Category>
            <text>{data.nt_wdate}</text>
          </Article>
          <Article>
            <Category>게시상태</Category>
            <Select
              type='Default'
              value={ show === false ? data.nt_show && setShow(data.nt_show) : show }
              style={{ width: 100, textAlign: 'center'}}
              onChange={(e) => setShow(e)}
              options={[
                { value: 'Y', label: '노출' },
                { value: 'N', label: '미노출' },
              ]}
              />
          </Article>
          <Article>
            <Category>제목<Pointer>*</Pointer></Category>
            <Input value={ title === false ? data.nt_title && setTitle(data.nt_title) : title } 
              placeholder='공지사항 제목을 입력하세요.'
              onChange={(e) => setTitle(e.target.value)}
            />
          </Article>
          <Article>
            <Category>내용<Pointer>*</Pointer></Category>
            <ReactSummernote 
              options={{width: 1500, height: 350}} 
              value={ content === false ? data.nt_content && setContent(data.nt_content) : content }
              onChange={(e) => setContent(e)}
              onImageUpload={onImageUpload}
            />
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/noticelist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => updateData()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Pointer = styled.span`
  color: ${ColorRed};
  margin-left: 3px;
  font-size: 20px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
const EditWrap = styled.div`
  display: flex;
  flex-direction: row;
`
export default NoticeListInfo;